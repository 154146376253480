import { Controller } from "@hotwired/stimulus";
import { Datepicker } from "vanillajs-datepicker";

export default class extends Controller {
  connect() {
    new Datepicker(this.element, {
      autohide: true,
      format: "D, M-dd-yyyy"
    });
  }
}
