import { Application } from "@hotwired/stimulus";

const application = Application.start()

// Import shares controllers
import RemovalsController from "./shares/removals_controller";
application.register("removals", RemovalsController);

// Import vendors controllers
import TomSelectController from "./vendors/tom_select_controller";
application.register("tom-select", TomSelectController);

import DatepickerController from "./vendors/datepicker_controller";
application.register("datepicker", DatepickerController);

import DropzoneController from "./vendors/dropzone_controller";
application.register("dropzone", DropzoneController);
