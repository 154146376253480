import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      controlInput: null,
      placeholder: "Tag family members",
      plugins: {
        "checkbox_options": {
          "checkedClassNames": ["multiple-checked"],
          "uncheckedClassNames": ["multiple-unchecked"],
        }
      }
    });
  }
}
